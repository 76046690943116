import React, { useState, useContext, useEffect } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const AwardsSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [awardSectionData, setAwardSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const awardSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-award-section-data/' + company_id + '/' + template_id
                    );
                    const result = await awardSection.json();

                    setAwardSectionData({ "awards": result && result.awards, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = awardSectionData && awardSectionData.section_details


    return (
        <div>
            <section className="spec_resort_area">
                <div className="container">
                {section_details && 
                    <div>
                        <div className="section-header text-center text-uppercase">
                            <h1>{section_details.section_title}</h1>
                        </div>
                        <div className="center_title">
                            <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                        </div>
                    </div>}
                </div>
                <section className={section_details && section_details.background_image && section_details.background_image ? "get_contact_area parallax-bg": "get_contact_area"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                    {awardSectionData && awardSectionData.awards && awardSectionData.awards.awards.length > 0 &&
                        <div className="container">

                            <div className="row spec_resort_inner">
                                {awardSectionData.awards.awards.map(function (item, i) {
                                    return (
                                        <div className="col-md-3 col-sm-6" key={i}>
                                            <div className="spec_resort_item">
                                                <img className="awards-images" src={config.wbImagesEndpoint + '/' + item} alt={awardSectionData.awards.awards_image_alt} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    }
                </section>
            </section>

        </div>
    );
}


export default AwardsSection;