import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
function CustomSection(props) {

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [customSectionData, setCustomSectionData] = useState();
    const [displayCustomSectionData, setDisplayCustomSectionData] = useState();


    let custom_section_id = props.section_id;
    let custom_section_name = props.name.replace(/[^a-zA-Z0-9 ]/g,"");
    custom_section_name = custom_section_name.replace(/ +/g, "_");
    custom_section_name = custom_section_name.toLowerCase();  

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id

                    const sectionData = await fetch(
                        config.apiEndpoint + '/frontview-custom/fetch-frontview-custom-sections-data/' + company_id + '/' + template_id
                    );
                    const result = await sectionData.json();
                                        
                    setCustomSectionData({ "data": result && result.custom_sections_data })

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    useEffect(() => {
        if (customSectionData) {
            if(customSectionData.data && customSectionData.data.length > 0){
                for(let i = 0;i < customSectionData.data.length;i++){
                    if(custom_section_id === customSectionData.data[i].id){
                        setDisplayCustomSectionData(customSectionData.data[i]);


                        let style = document.createElement('style');
                        style.type = 'text/css';
                        style.innerHTML = customSectionData.data[i].css;

                        let custom_section = document.getElementById("custom_section_" + custom_section_name);

                        if (custom_section) {
                            custom_section.append(style);
                        }
                        

                    }
                }
            }
        }

    }, [customSectionData,custom_section_id])

    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });

    return (
        <div id={"custom_section_"+custom_section_name}>            
            {displayCustomSectionData  && <div  dangerouslySetInnerHTML={{ __html:displayCustomSectionData.html }}></div>}
        </div>

    );
}


export default CustomSection;