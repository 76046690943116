import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'

const PackageSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [packageSectionData, setPackageSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const packageSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-package-section-data/' + company_id + '/' + template_id
                    );
                    const result = await packageSection.json();
                        
                    setPackageSectionData({ "packages_data": result && result.hotel_packages, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = packageSectionData && packageSectionData.section_details


    return (
        <div>
            <div className="container">
            {section_details && 
                    <div>
                        <div className="section-header text-center text-uppercase">
                            <h1>{section_details.section_title}</h1>
                        </div>
                        <div className="center_title">
                            <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                        </div>
                    </div>}
            </div>
            <div className={section_details && section_details.background_image && "parallax-bg"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
            {packageSectionData && packageSectionData.packages_data && packageSectionData.packages_data.length > 0 && <div className="packages">
                <section className="event_two_area" style={{ padding: "30px 0px" }}>
                    <div className="container">
                        <div className="row event_two_inner">
                            <OwlCarousel
                                className="owl-theme"
                                autoplay={true}
                                nav={true}
                                loop
                                margin={10}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    800: {
                                        items: 2
                                    },
                                    1200: {
                                        items: 3
                                    },
                                }}

                            >
                                {packageSectionData.packages_data.map(function (item, i) {
                                    var send_title = item.package_title.replace(/[^a-zA-Z0-9 ]/g, "");
                                    send_title = send_title.replace(/ +/g, "_");
                                    send_title = send_title.replace(",", "");
                                    send_title = send_title.toLowerCase();


                                    return (
                                        <div className="item" key={i}>
                                            <div className="col-md-4 col-sm-4" style={{ width: "100%" }}>
                                            <div className="explor_item">
                                                <div className="event_two_item">

                                                    <Link className="event_two_img" to={{
                                                        pathname: config.PATH + '' + send_title
                                                    }}>
                                                        <img src={config.wbImagesEndpoint + '/' + item.package_image} alt={item.package_image_alt} style={{ height: "245px" }} />
                                                    </Link>
                                                    <div className="event_two_text">
                                                        <div className="media">

                                                            <div className="media-body">
                                                                <Link to={{
                                                                    pathname: config.PATH + '' + send_title
                                                                }}><h4>{item.package_title}</h4>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <hr></hr>
                                                        <br />
                                                        <div className="text-center">
                                                            <Link className="book_now_btn" to={{
                                                                pathname: config.PATH + '' + send_title
                                                            }} style={{ fontFamily: websitefont, textDecoration: "none" }}>read more</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </OwlCarousel>
                        </div>
                    </div>
                </section>
            </div>}
            </div>
        </div>
    );
}

export default PackageSection;