import React, { useState, useEffect, useContext } from 'react';
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import Slider from './Slider'
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import _debounce from 'lodash.debounce'
import FooterFixed from './FooterFixed'
import moment from 'moment'
import { DateRangePicker } from 'react-dates';
import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";
import OfferSection from './sections/OfferSection'
import AboutSection from './sections/AboutSection'
import ServiceSection from './sections/ServiceSection'
import PackageSection from './sections/PackageSection';
import RoomSection from './sections/RoomSection';
import GallerySection from './sections/GallerySection';
import TestimonialSection from './sections/TestimonialSection';
import FacebookSection from './sections/FacebookSection';
import BlogSection from './sections/BlogSection';
import EventSection from './sections/EventSection';
import NewsletterSection from './sections/NewsletterSection';
import ContactSection from './sections/ContactSection';
import NearbyPlacesSection from './sections/NearbyPlacesSection';
import FacilitiesSection from './sections/FacilitiesSection';
import PromotionalBannerSection from './sections/PromotionalBannerSection';
import PromotionSection from './sections/PromotionSection'
import AwardsSection from './sections/AwardsSection';
import BookingBenefitsSection from './sections/BookingBenefitsSection';
import CustomFooterSection from './sections/CustomFooterSection';
import CustomSection from './sections/CustomSection';

function Home() {
    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;

    const [homePageData, setHomePageData] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let display_default_footer = true;

                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-home-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.home_page_data && result.home_page_data.page_section) {
                        for (let i = 0; i < result.home_page_data.page_section.length; i++) {
                            if (result.home_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }

                    setHomePageData({ "home_page_data": result && result.home_page_data, "page_section": result && result.home_page_data && result.home_page_data.page_section, "all_hotels_data": result && result.all_hotels, "display_default_footer": display_default_footer })

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    const [screen_size, setScreensize] = useState(window.innerWidth)

    var scrolled = false;
    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        scrolled = true;
    });


    return (

        <div>
            {(homePageData === undefined) ? <div className="loader"></div> : <Header />}

            {homePageData && homePageData.home_page_data &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{homePageData.home_page_data.seo_title}</title>
                    <meta name="description" content={homePageData.home_page_data.seo_description} />
                </MetaTags>
            }

            {homePageData && <Slider />}

            {homePageData && homePageData.all_hotels_data && (screen_size > 767) ? <DatePickerWidget hoteldata={homePageData.all_hotels_data} companyDetails={companyDetails}/> : ''}

            {homePageData && homePageData.page_section && homePageData.page_section.map(function (item, i) {

                if (item.section_names === "Promotional Banner") {
                    return (
                        <PromotionalBannerSection key={i} />
                    )
                }

                if (item.section_names === "Newsletter") {
                    return (
                        <NewsletterSection key={i} />
                    )
                }

                if (item.section_names === "About Us") {
                    return (
                        <AboutSection key={i} />
                    )
                }

                if (item.section_names === "Rooms") {
                    return (
                        <RoomSection key={i} />
                    )
                }

                if (item.section_names === "Gallery") {
                    return (
                        <GallerySection key={i} />
                    )
                }

                if (item.section_names === "Contact Us") {
                    return (
                        <ContactSection key={i} />
                    )
                }

                if (item.section_names === "Services") {
                    return (
                        <ServiceSection key={i} />
                    )
                }


                if (item.section_names === "Nearby Places") {
                    return (
                        <NearbyPlacesSection key={i} />
                    )
                }

                if (item.section_names === "Testimonial") {
                    return (
                        <TestimonialSection key={i} />
                    )
                }


                if (item.section_names === "Facility") {
                    return (
                        <FacilitiesSection key={i} />
                    )
                }


                if (item.section_names === "Package") {
                    return (
                        <PackageSection key={i} />
                    )
                }

                if (item.section_names === "Blog") {
                    return (
                        <BlogSection key={i} />
                    )
                }

                if (item.section_names === "Event") {
                    return (
                        <EventSection key={i} />
                    )
                }

                if (item.section_names === "Offer") {
                    return (
                        <OfferSection key={i} />
                    )
                }


                if (item.section_names === "Facebook") {
                    return (
                        <FacebookSection key={i} />
                    )
                }

                if (item.section_names === "Promotions") {
                    return (
                        <PromotionSection key={i} />

                    )
                }

                if (item.section_names === "Awards") {
                    return (
                        <AwardsSection key={i} />
                    )
                }


                if (item.section_names === "Booking Benfits") {
                    return (
                        <BookingBenefitsSection data={'home'} key={i} />
                    )
                }

                if (item.section_type === "custom") {
                    return (
                        <CustomSection key={i} section_id={item.id} name={item.section_names}/>
                    )
                }
                
                if (item.section_names === "Custom Footer") {
                    return (
                        <CustomFooterSection key={i} />
                    )
                }
            })}

            {homePageData && homePageData.display_default_footer && homePageData.display_default_footer === true && <Footer />}
            {homePageData && <FooterFixed />}

        </div>
    );
}



function DatePickerWidget(props) {
    let hotel_details = props.hoteldata;
    let companyDetails = props.companyDetails;
    const [hotelId, setHotelId] = useState(hotel_details && hotel_details[0].hotel_id);
    const [focusedInput, setfocusedInput] = useState(null)

    const [fromdate, setFromDate] = useState(moment())
    const [todate, setToDate] = useState(moment(fromdate).add(1, 'days'))
    const hotelName = (e) => {
        setHotelId(e);
    }
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    var iframe
    let encryptData
    if (isNaN(from_date) && isNaN(to_date)) {

    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotelId + '|' + "" + '|' + "" + '|' + ""
        encryptData = btoa(allData);
        // iframe = encryptData

    }

    return (

        <div className="date-picker-wrap">
            <div className="row">
                {hotel_details && hotel_details.length > 1 ? <div className="col-md-4 col-sm-4 form-group">
                    <label>Select Hotel</label>
                    <select className="form-control select-form" onChange={(e) => { hotelName(e.target.value) }}>
                        {hotel_details && hotel_details.map(function (item, i) {
                            return (
                                <option key={i} value={item.hotel_id}>{item.hotel_name}</option>
                            )
                        })}
                    </select>
                </div> :
                    <div className="col-md-4 col-sm-4 form-group">
                        <label>Hotel</label>
                        <input className="date-picker-input" type="text" defaultValue={hotel_details[0].hotel_name} readOnly />
                    </div>}


                <div className="col-md-4 col-sm-4 form-group">
                    <DateRangePicker
                        startDate={fromdate}
                        startDateId="your_unique_start_date_id"
                        endDate={todate}
                        endDateId="your_unique_end_date_id"
                        onDatesChange={({ startDate, endDate }) => {
                            setFromDate(startDate)
                            setToDate(endDate)
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={focusedInput => setfocusedInput(focusedInput)}
                        displayFormat='DD-MM-YYYY'
                        customArrowIcon="|"
                        block={true}
                    />
                </div>

                <div className="col-md-4 col-sm-4">
                    <div className="text-left date-picker-btn">
                        {/* <Link className="book_now_btn"
                            to={{
                                pathname: config.PATH + 'booknow',
                                search: '?' + (iframe),
                            }}
                        >Book Now</Link> */}
                        <a className="book_now_btn" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Home;
