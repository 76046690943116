import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-datepicker/dist/react-datepicker.css";

const GallerySection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [gallerySectionData, setGallerySectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const gallerySection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-gallery-section-data/' + company_id + '/' + template_id
                    );
                    const result = await gallerySection.json();

                    setGallerySectionData({ "gallery_data": result && result.gallery_data, "section_details": result && result.section_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    let websitefont = '';
    let section_details = gallerySectionData && gallerySectionData.section_details

    const images = [];

    const [open, isopen] = useState(false)
    const [index, setindex] = useState()
    const Toggle = (name, idx) => {
        isopen(true);
        setindex(idx);
    }
    var allImg = []
    allImg = gallerySectionData && gallerySectionData.gallery_data && gallerySectionData.gallery_data[gallerySectionData.gallery_data.length - 1];

    return (

        <div>
            <div className="container">
                {section_details &&
                    <div>
                        <div className="section-header text-center text-uppercase">
                            <h1>{section_details.section_title}</h1>
                        </div>
                        <div className="center_title">
                            <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                        </div>
                    </div>}
            </div>
            <div className={section_details && section_details.background_image && "parallax-bg"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>

                <div className="resort_gallery_inner" >
                    <div>
                        {allImg && <div className="row grid_gallery_item_inner">
                            <div className="gallery-image-display">
                                <ul>
                                    {allImg.map(function (item, i) {
                                        if (i < 10) {
                                            images.push(config.wbImagesEndpoint + "/" + item)
                                            return (
                                                <li key={i}><img src={config.wbImagesEndpoint + '/' + item} alt="" onClick={() => Toggle(item, i)} /></li>
                                            )
                                        }
                                    })}
                                    <li></li>
                                </ul>
                            </div>

                        </div>}
                    </div>

                </div>
                {open && (
                    <Lightbox
                        className=""
                        mainSrc={images[index]}
                        thumbnailWidth='150px'
                        thumbnailHeight='150px'
                        nextSrc={images[(index + 1) % images.length]}
                        prevSrc={images[(index + images.length - 1) % images.length]}
                        onCloseRequest={() => isopen(false)}
                        onMovePrevRequest={() =>
                            setindex((index + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setindex((index + 1) % images.length)
                        } />
                )}

            </div>
        </div>
    )
}

export default GallerySection;
