import React, { useState, useContext, useEffect } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import { Link } from 'react-router-dom'

const ServiceSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [servicesSectionData, setServicesSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let name_hotel;
                    const servicesSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-services-section-data/' + company_id + '/' + template_id
                    );
                    const result = await servicesSection.json();

                    if (result && result.hotel_name) {
                        name_hotel = result.hotel_name.hotel_name.replace(/[^a-zA-Z0-9 ]/g, "");
                        name_hotel = name_hotel.replace(/ +/g, "_");
                        name_hotel = name_hotel.toLowerCase();
                    }

                    setServicesSectionData({ "selected_services": result && result.selected_services, "section_details": result && result.section_details, "hotel_name": name_hotel });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = servicesSectionData && servicesSectionData.section_details

    return (
        <section className="spec_resort_area">
            <div className="container">
            {section_details && 
                    <div>
                        <div className="section-header text-center text-uppercase">
                            <h1>{section_details.section_title}</h1>
                        </div>
                        <div className="center_title">
                            <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                        </div>
                    </div>}
            </div>
            <div className={section_details && section_details.background_image && "parallax-bg"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container">
                    <div className="row spec_resort_inner">
                        {servicesSectionData && servicesSectionData.selected_services && servicesSectionData.selected_services.map(function (item, i) {
                            var send_title = item.title.replace(/[^a-zA-Z0-9 ]/g, "");
                            send_title = send_title.replace(/ +/g, "_");
                            send_title = send_title.toLowerCase();
                            if (i < 3) {
                                return (
                                    <div className="col-md-4 col-sm-6" key={i}>
                                        <div className="spec_resort_item">
                                            <Link className="resort_img"
                                                to={{
                                                    pathname: config.PATH + 'page',
                                                    search: servicesSectionData.hotel_name + '/' + send_title
                                                }}
                                            ><img src={config.wbImagesEndpoint + '/' + item.services_image} alt={item.services_image_alt} /></Link>
                                            <h4 className="service-title">{item.title}</h4>
                                        </div>
                                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                    </div>
                                );
                            }
                        })}

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;