import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import FooterFixed from './FooterFixed'
import $ from "jquery"
import _debounce from 'lodash.debounce'
import history from '../../history'
import CustomFooterSection from './sections/CustomFooterSection';

function PackageDetails() {
    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [packageDetailsPageData, setPackageDetailsPageData] = useState();

    let hotel_package_url;
    hotel_package_url = window.location.pathname;
    hotel_package_url = hotel_package_url.replace(config.PATH, "");


    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let package_details_det;
                    let display_default_footer = true;

                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-package-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.package_page_data && result.package_page_data.page_section) {
                        for (let i = 0; i < result.package_page_data.page_section.length; i++) {
                            if (result.package_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }

                    if (result && result.hotel_packages) {
                        let check_special_char;
                        for (let i = 0; i < result.hotel_packages.length; i++) {
                            check_special_char = result.hotel_packages[i].package_title.replace(/[^a-zA-Z0-9 ]/g, "");
                            check_special_char = check_special_char.replace(/ +/g, "_");
                            check_special_char = check_special_char.toLowerCase();

                            if (check_special_char === hotel_package_url) {
                                package_details_det = result.hotel_packages[i];
                            }
                        }
                    }


                    setPackageDetailsPageData({ "hotel_packages": package_details_det,"display_default_footer": display_default_footer });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    const [screen_height, setScreenheight] = useState();
    const [screen_size, setScreensize] = useState(window.innerWidth)
    const [a, seta] = useState('active');
    const [b, setb] = useState();
    const [c, setc] = useState();
    const [d, setd] = useState();
    const [e, sete] = useState();

    const Scrolling = () => {
        seta("active")
        setb(" ")
        setc(" ")
        setd(" ")
        sete(" ")
        var x = (screen_size > 1023 ? 175 : 400);
        $('html,body').animate({
            scrollTop: $("#overview").offset().top - x
        },
            '3000');
    }
    const Scrolling1 = () => {
        seta(" ")
        setb("active")
        setc(" ")
        setd(" ")
        sete(" ")

        var x = (screen_size > 1023 ? 175 : 200);
        $('html,body').animate({
            scrollTop: $("#inclusion").offset().top - x
        },
            '3000');
    }

    const Scrolling2 = () => {
        seta(" ")
        setb(" ")
        setc("active")
        setd(" ")
        sete(" ")

        var x = (screen_size > 1023 ? 175 : 200);
        $('html,body').animate({
            scrollTop: $("#exclusion").offset().top - x
        },
            '3000');
    }


    const Scrolling3 = () => {
        seta(" ")
        setb(" ")
        setc(" ")
        setd("active")
        sete(" ")

        var x = (screen_size > 1023 ? 175 : 220);
        $('html,body').animate({
            scrollTop: $("#itinerary").offset().top - x
        },
            '3000');
    }
    const Scrolling4 = () => {
        seta(" ")
        setb(" ")
        setc(" ")
        setd(" ")
        sete("active")

        var x = (screen_size > 1023 ? 175 : 240);
        $('html,body').animate({
            scrollTop: $("#cancellation").offset().top - x
        },
            '3000');
    }

    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });
    window.addEventListener('scroll', function () {
        setScreenheight(window.scrollY);
    });

    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });

    let websitefont = '';

    let video_banner_link = packageDetailsPageData && packageDetailsPageData.hotel_packages && packageDetailsPageData.hotel_packages.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = packageDetailsPageData && packageDetailsPageData.hotel_packages && packageDetailsPageData.hotel_packages.video_banner

    return (
        <div className="packages-wrapper">
            {(packageDetailsPageData === undefined) ? <div className="loader"></div> : <Header />}

            {packageDetailsPageData && packageDetailsPageData.hotel_packages &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{packageDetailsPageData.hotel_packages.seo_title}</title>
                    <meta name="description" content={packageDetailsPageData.hotel_packages.seo_description} />
                </MetaTags>
            }

            {packageDetailsPageData && packageDetailsPageData.hotel_packages && packageDetailsPageData.hotel_packages.banner &&
                <section className="banner_area" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + packageDetailsPageData.hotel_packages.banner + ")" }}>
                    <div className="container">
                        <div className="banner_inner_content">
                            <h3 >{packageDetailsPageData.hotel_packages.banner_title}</h3>
                            <ul>
                                <li className="active"><a onClick={() => history.push(config.PATH + 'home')}>Home</a></li>
                                <li><a style={{ color: 'white' }}>{packageDetailsPageData.hotel_packages.banner_title}</a></li>
                            </ul>
                        </div>
                    </div>
                </section>}


            {packageDetailsPageData && packageDetailsPageData.hotel_packages && packageDetailsPageData.hotel_packages.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + packageDetailsPageData.hotel_packages.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + packageDetailsPageData.hotel_packages.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}


            {packageDetailsPageData && packageDetailsPageData.hotel_packages && packageDetailsPageData.hotel_packages.page_title && <div className="container">
                <div className="page-header text-center text-uppercase">
                    <h1>{packageDetailsPageData.hotel_packages.page_title}</h1>
                </div>
                <div className="center_title">
                    <p dangerouslySetInnerHTML={{ __html: packageDetailsPageData.hotel_packages.page_description }}></p>
                </div>
            </div>}


            { packageDetailsPageData && packageDetailsPageData.hotel_packages &&
                <section className="room_details_area">
                    <div className="container">
                        <div className="row room_details_inner">
                            <div className="col-md-12">
                                <div className="room_details_content">
                                    <div className="room_d_main_text" style={{ paddingBottom: 0 }}>
                                        <div className="room_details_img package-image">
                                            <div className="item">
                                                <img className="package_show_img" src={config.wbImagesEndpoint + '/' + packageDetailsPageData.hotel_packages.package_image} alt={packageDetailsPageData.hotel_packages.package_image_alt} style={{ width: "100%" }} />
                                            </div>
                                            <div className="package-title" style={{ fontFamily: websitefont }}><span className="package-title-bg-color">{packageDetailsPageData.hotel_packages.package_title}</span></div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="scrolled">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12">
                                                <ul className={(screen_height > 430 && screen_size > 900) ? "fixed-navbar fixed-package-navbar" : "fixed-navbar"}>
                                                    <li><a className={a} onClick={() => Scrolling()}>{packageDetailsPageData.hotel_packages.overview_title.substr(0, 25) + '...'}</a></li>

                                                    <li><a className={b} onClick={() => Scrolling1()}>{packageDetailsPageData.hotel_packages.inclusion_title}</a></li>

                                                    <li><a className={c} onClick={() => Scrolling2()}>{packageDetailsPageData.hotel_packages.exclusion_title}</a></li>

                                                    <li><a className={d} onClick={() => Scrolling3()}>{packageDetailsPageData.hotel_packages.itinerary_title}</a></li>

                                                    <li><a className={e} onClick={() => Scrolling4()}>{packageDetailsPageData.hotel_packages.cancellation_title}</a></li>


                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="room_service_list">
                                            <h3 className="room_d_title" style={{ paddingBottom: 0 }} id="overview">{packageDetailsPageData.hotel_packages.overview_title}</h3>
                                            <hr></hr>
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: packageDetailsPageData.hotel_packages.overview_details }} style={{ fontFamily: websitefont }} ></div>
                                        </div>
                                        <div className="room_service_list">
                                            <h3 className="room_d_title" id="inclusion" style={{ paddingBottom: 0 }}>{packageDetailsPageData.hotel_packages.inclusion_title}</h3>
                                            <hr></hr>
                                            <br />

                                            <div dangerouslySetInnerHTML={{ __html: packageDetailsPageData.hotel_packages.inclusion_details }} style={{ fontFamily: websitefont }} ></div>
                                        </div>

                                        <div className="room_service_list">
                                            <h3 className="room_d_title" style={{ paddingBottom: 0 }} id="exclusion">{packageDetailsPageData.hotel_packages.exclusion_title}</h3>
                                            <hr></hr>
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: packageDetailsPageData.hotel_packages.exclusion_details }} style={{ fontFamily: websitefont }} ></div>
                                        </div>




                                        <div className="room_service_list">
                                            <h3 className="room_d_title" id="itinerary" style={{ paddingBottom: 0 }}>{packageDetailsPageData.hotel_packages.itinerary_title}</h3>
                                            <hr></hr>
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: packageDetailsPageData.hotel_packages.itinerary_details }} style={{ fontFamily: websitefont }}></div>
                                        </div>

                                        <div className="room_service_list">
                                            <h3 className="room_d_title" id="cancellation" style={{ paddingBottom: 0 }}>{packageDetailsPageData.hotel_packages.cancellation_title}</h3>
                                            <hr></hr>
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: packageDetailsPageData.hotel_packages.cancellation_details }} style={{ fontFamily: websitefont }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {packageDetailsPageData && packageDetailsPageData.display_default_footer === false && <CustomFooterSection />}
            {packageDetailsPageData && packageDetailsPageData.display_default_footer === true && <Footer />}
            {packageDetailsPageData && <FooterFixed />}

        </div>
    )
}
export default PackageDetails;