import React, { useState, useContext, useEffect } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const BookingBenefitsSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [bookingBenefitsSectionData, setBookingBenefitsSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const awardSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-booking-benefits-section-data/' + company_id + '/' + template_id
                    );
                    const result = await awardSection.json();

                    setBookingBenefitsSectionData({ "benefits": result && result.selected_benefits });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';

    
    return (
        <div>
             {bookingBenefitsSectionData && <BookDirectDesktop desktop_data={bookingBenefitsSectionData} />}
            {bookingBenefitsSectionData && <BookDirectMobile mobile_data={bookingBenefitsSectionData} />}

        </div>

    );
}


function BookDirectDesktop(props) {

    let benefits_data = props.desktop_data

    return (
        <div className="bookDirect-wrap desktop-book-direct">
            <div className="bookingDirect-div">
                <div className="why-book">
                    <h3>Why</h3>
                    <p>Book direct with us ?</p>
                </div>
                <div className="benfits-all-div">
                    
                {benefits_data && benefits_data.benefits.map(function (items, i) {
                        return (
                            <div className="benft-all" key={i}>
                                <div className="text-center">
                                    <i className={items.icon}></i>
                                </div>
                                <p>{items.benefit_title}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>

    )

}


function BookDirectMobile(props) {

    let benefits_data = props.mobile_data
    let websitefont = ''

    return (
        <div className="bookDirect-wrap mobile-book-direct">
            <div className="text-center">
                <h4>Why BOOK DIRECT with us ?</h4>
            </div>
            <div className="bookingDirect-div">
                
                <div className="benfits-all-div">
                    <div className="scrolling-wrapper1">
                    {benefits_data && benefits_data.benefits.map(function(items, i){   
                    return (
                             <div className="benft-all card" key={i}>
                             <div className="text-center"><i className={items.icon}></i></div>
                             <p>{items.benefit_title}</p>
                         </div>
                         )    
                     })}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingBenefitsSection;





